// App.js
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GlobalProvider } from './context/ContextProvider';
import { useAppDispatch } from './hooks/storeHooks';
import { appInit } from './sagaStore/slices';
import { runSaga } from './sagaStore/store';
import rootSaga from './sagas';
import MainV2Page from './pages/MainV2/MainV2Page';
import AnimaraPage from './pages/ANIMARA/AnimaraPage';
import Anitoken from './pages/AniTokenomic/AniTokenomicPage';
import Animics from './pages/story/StoryPage';
import PrivacyPolicyPage from './pages/PrivacyPolicy/PrivacyPolicyPage';
import TermsConditionsPage from './pages/TermsConditions/TermsConditionsPage';
import Error404Page from './pages/Error404/Error404.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StoryPage from './pages/story/StoryPage';
import HighFidelityComicPage from './pages/story/HighFidelityComicPage';
import AnimaraStory from './pages/story/AnimaraStory';
import ComicContent from './pages/story/ComicContent';
import AniPaperPage from './pages/AniPaper/AniPaperPage.js';
import AniToEarnPage from './pages/AniToEarn/AniToEarnPage.js';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    runSaga(rootSaga);
    dispatch(appInit());
  }, [dispatch]);

  return (
    <GlobalProvider>
      <Router>
        <Routes>
          <Route path="/" element={<MainV2Page />} />
          <Route path="/animara" element={<AnimaraPage />} />
          <Route path="/anipaper" element={<AniPaperPage />} />
          <Route path="/aniearn" element={<AniToEarnPage />} />
          <Route path="/anitoken" element={<Anitoken />} />
          <Route path="/animics" element={<Animics />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-and-conditions" element={<TermsConditionsPage />} />
          <Route path="*" element={<Error404Page />} />
          <Route path="/story" element={<StoryPage />} />
          <Route path="/story/high-fidelity-hero" element={<HighFidelityComicPage />} />
          <Route path="/story/animara-story" element={<AnimaraStory />} />
          <Route path="/story/comic" element={<ComicContent />} />
        </Routes>
      </Router>
    </GlobalProvider>
  );
}

export default App;
