import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ComicCard = ({ comic, index }) => {
  const { t, i18n } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [isComicHovered, setComicHovered] = useState(false);
  const onImageLoaded = () => setLoaded(true);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/story/animara-story?type=${comic.type}&id=${comic.comicId}`);
  };

  return (
    <div
      className="relative flex justify-between items-start group"
      onMouseDown={() => handleClick()}
    >
      {!loaded && (
        <div className="animate-pulse w-[18rem] h-[15rem] xl:h-[21rem] bg-gray-300 rounded-2xl"></div>
      )}

      <div
        className={`bg-white bg-opacity-30 rounded-[16px] shadow-stone-500 shadow-md hover:shadow-orange-300 hover:shadow-lg transition-all duration-600 ease-in-out ${
          loaded ? "" : "hidden"
        }`}
        style={{ backgroundImage: `url('/story/comic-bg.webp')` }}
        onMouseEnter={() => setComicHovered(true)}
        onMouseLeave={() => setComicHovered(false)}
      >
        <div className="relative flex justify-center items-center p-[1rem] w-68 h-68">
          <img
            src={comic.coverUrl}
            alt={`Comic ${index + 1}`}
            className={`object-cover rounded-[12px] h-full y-full ${
              loaded ? "" : "hidden"
            }`}
            onLoad={onImageLoaded}
          />

          <div className={`absolute inset-0 flex justify-center items-center rounded-[12px] m-[1rem] transition-all duration-600 ease-in-out ${isComicHovered ? "opacity-100 bg-gradient-to-b from-[rgba(0,0,0,0.5)] to-[rgba(0,0,0,0.8)]" : "opacity-0 bg-none"}`}>
            <button
              onClick={handleClick}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-[2rem] lg:px-[1.5rem] py-[0.5rem] bg-[#FFB23F] drop-shadow-lg text-white rounded-2xl text-base lg:text-lg font-outfit font-bold whitespace-nowrap"
            >
              {t("read now")}
            </button>
          </div>
        </div>

        <div className="w-full p-[1rem] pt-0">
          <p
            className="text-white text-3xl font-normal font-bigNoodle line-clamp-1"
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
            }}
          >
            {comic.title[i18n.language.startsWith("en") ? "en" : "cn"]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ComicCard;
