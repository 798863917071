import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const MainV2AniWalk = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="relative grid grid-rows-1 h-full max-w-[1920px] max-h-[1080px] py-0 lg:py-12 w-full content-center bg-[#000000]">

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    
      <div className="col-start-1 row-start-1 hidden lg:flex h-auto pointer-events-none gradient-overlay">
        <video
          src="https://storage.animara.world/city-walk.mp4"
          className="w-auto h-auto max-h-[64rem] ml-auto mr-0"
          autoPlay
          loop
          muted
          playsInline
        />
      </div>
      <div className="col-start-1 row-start-1 lg:hidden grid grid-rows-1">
        <img
          className="col-start-1 row-start-1 w-full h-full object-cover z-[49]"
          src="/assets/mainV2/animal-walk.webp"
          alt="Event Background"
        />
        <div className="col-start-1 row-start-1 flex top-[-1px] left-0 pointer-events-none z-[50]">
          <img
            className="w-full h-full"
            src="/assets/mainV2/Anicity-walk-bg.webp"
            alt="Event Background"
          />
        </div>
      </div>
      
    </div>
  );
};

export default MainV2AniWalk;