import React, { useState, useLayoutEffect, useEffect, useMemo } from 'react';
import { useLocation } from "react-router-dom";
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';
import AniToEarnTapToEarn from './AniToEarnTapToEarn';
import AniToEarnPlayToEarn from './AniToEarnPlayToEarn';
import CrossIcon from "../../component/icons/CrossIcon";
import ReactPlayer from 'react-player';
import AniToEarnHuntToEarn from './AniToEarnHuntToEarn';

const AniToEarnPage = () => {
  const [notifyContent, setNotifyContent] = useState(false);
  const [closeLoading, setCloseLoading] = useState(false);
  const [isPageReady, setIsPageReady] = useState(false);
  const [isOpenVideo, setIsOpenVideo] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const location = useLocation();
  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);

  const handleCloseVideo = () => {
    setIsOpenVideo(null)
  };

  useEffect(() => {
    if(isOpenVideo){
      setVideoUrl(isOpenVideo)
    }else{
      setVideoUrl("");
    }
  },[isOpenVideo]);
  
  useEffect(() => {
    setTimeout(() => {
      if (isPageReady) {
        setCloseLoading(true);
        setTimeout(() => {
          setNotifyContent(true);
        }, 700);
      }
    }, 1000);
  }, [isPageReady]);

  const videosList = [
    "https://storage.animara.world/ANI_T2E_Trailer.mp4",
    "https://storage.animara.world/ANI_T2E_PromotionalVid.mp4",
    "https://storage.animara.world/ANI_P2E_Horror_v2.mp4",
    "https://storage.animara.world/ANI_P2E_Horror_v1.mp4",
    "https://storage.animara.world/ANI_P2E_short_FunTasticShowdown.mp4",
    "https://storage.animara.world/ANI_P2E_short_BeatTheBeatz.mp4",
    "https://storage.animara.world/ANI_P2E_short_CritterDash.mp4",
    "https://storage.animara.world/ANI_P2E_short_PawSomeFrenzy.mp4",
    "https://storage.animara.world/ANI_H2E_TreasureEnergyMine.mp4",
    "https://storage.animara.world/ANI_H2E_CityTreasureHunt.mp4",
    "https://storage.animara.world/ANI_H2E_MysticShrineHunt.mp4"
  ];

  return (
    <div className="bg-black">
      <div className={`fixed bg-black w-full h-full flex items-center justify-center overflow-y-hidden ${notifyContent ? 'hidden' : 'block'} transition-all duration-700 ${closeLoading ? 'bg-opacity-0' : 'bg-opacity-100'}`}>
        <img
          className={`w-[268px] h-auto animate-pulse`}
          src="/assets/mainV2/hero-loading.png"
          alt="hero loading"
        />
      </div>
      
      <div className={`relative flex flex-col overflow-x-hidden items-center justify-center place-content overflow-hidden bg-black z-0  transition-all duration-700 ${closeLoading ? 'opacity-100' : 'opacity-0'}`}>
        <div className={`fixed inset-0 w-screen h-[100vh] ${isOpenVideo ? "opacity-100 z-[99999]" : "opacity-0 z-[-100]"} transition-all duration-300 ease-in-out flex flex-col justify-center items-center lg:items-center gap-[2rem] backdrop-blur-[50px]`}
        onClick={handleCloseVideo}>
          <div className="absolute inset-0 bg-[#000000] opacity-20 z-[1]"></div>
          <div className='relative max-h-[85%] overflow-hidden z-[10]'>
            {videosList.map((video, index) => {
              if(videoUrl === video){
                return(
                  <ReactPlayer
                    url={video}
                    key={video + index}
                    width="100%"
                    height="100%"
                    controls
                    playing={videoUrl === video}
                    onClick={(event) => {
                      event.stopPropagation(); 
                    }}
                  />
                );
              }
            })}
          </div>
        </div>
        <Navbar isSticky/>
        <AniToEarnTapToEarn setIsOpenVideo={setIsOpenVideo} setIsPageReady={setIsPageReady} />
        <AniToEarnPlayToEarn setIsOpenVideo={setIsOpenVideo}/>
        <AniToEarnHuntToEarn setIsOpenVideo={setIsOpenVideo}/>
        <Footer />
      </div>
    </div>
  )
}

export default AniToEarnPage;