import React from "react";

const Divider = ({ fill, width = "24", height = "24", stroke = "#3C3C3C" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 2 30"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 0V30" stroke={stroke} />
  </svg>
);

export default Divider;
