import React from "react";
import { useLocation } from "react-router-dom";
import AnimaraStoryMain from "./AnimaraStoryMain";
import Footer from "../../component/Footer";
import ToTopButton from "../../component/ToTopButton";

const AnimaraStory = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const comicId = searchParams.get("id"); 
  const comicType = searchParams.get("type"); 
  

  return (
    <div className="bg-black flex flex-col overflow-x-hidden overflow-auto items-center justify-center ">
      <AnimaraStoryMain comicId={comicId} comicType={comicType} />
      <ToTopButton />
      {/* <Footer /> */}
    </div>
  );
};

export default AnimaraStory;
