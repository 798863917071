import React, { useState, useRef, useEffect } from "react";
import ChevronUp from "../../component/icons/ChevronUp";
import ChevronDown from "../../component/icons/ChevronDown";
import ChevronLeft from "../../component/icons/ChevronLeft";
import Divider from "../../component/icons/Divider";
import ZoomIn from "../../component/icons/ZoomIn";
import { PropagateLoader } from "react-spinners";
import { useAppDispatch } from "../../hooks/storeHooks";
import {
  getComic,
  useComic,
  resetComicList,
} from "../../sagaStore/slices/comicSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AnimaraStoryMain = ({ comicId, comicType }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const comic = useComic();
  const videoRefs = useRef([]);
  const pagesRef = useRef([]);
  const comicNum = parseInt(comicType);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingVideos, setLoadingVideos] = useState({});

  const toggleFullScreen = () => {
    const element = document.getElementById("container");

    if (!document.fullscreenElement) {
      element.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen().catch((err) => {
        console.error(
          `Error attempting to disable full-screen mode: ${err.message} (${err.name})`
        );
      });
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Prevent scrolling on the body
    return () => {
      document.body.style.overflow = "auto"; // Re-enable scrolling when the component unmounts
    };
  }, []);

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      dispatch(getComic(comicId));
    }, 1000);

    return () => {
      clearTimeout(timer);
      dispatch(resetComicList());
    };
  }, [dispatch, comicId]);

  useEffect(() => {
    if (comic) {
      setTimeout(() => {
        setLoading(false);
      }, 250);
    }
  }, [comic]);

  useEffect(() => {
    // Autoplay the video of the current page
    if (comic && comic.type === 2) {
      const video = videoRefs.current[currentPage - 1];
  
      if (video) {
        // Mark video as loading
        setLoadingVideos((prev) => ({ ...prev, [currentPage - 1]: true }));
  
        // Try to play the video
        const playVideo = async () => {
          try {
            await video.play();
          } catch (err) {
            console.error(
              `Autoplay failed for video ${currentPage - 1}: ${err.message}`
            );
          } finally {
            // Mark video as loaded regardless of success
            setLoadingVideos((prev) => ({ ...prev, [currentPage - 1]: false }));
          }
        };
  
        playVideo();
  
        // Event listener for "playing" to mark loading as false
        const handlePlaying = () =>
          setLoadingVideos((prev) => ({ ...prev, [currentPage - 1]: false }));
  
        // Event listener for "waiting" to mark loading as true
        const handleWaiting = () =>
          setLoadingVideos((prev) => ({ ...prev, [currentPage - 1]: true }));
  
        // Add event listeners
        video.addEventListener("playing", handlePlaying);
        video.addEventListener("waiting", handleWaiting);
  
        // Cleanup event listeners when effect re-runs or unmounts
        return () => {
          video.removeEventListener("playing", handlePlaying);
          video.removeEventListener("waiting", handleWaiting);
        };
      }
    }
  }, [currentPage, comic]);

  const handleBack = () => {
    navigate(-1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(Number(page));
    // Optional: Scroll the specific page into view within the container
    if (pagesRef.current[page - 1]) {
      pagesRef.current[page - 1].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const renderPageOptions = () => {
    const pages = [];
    if (comic && comic.content) {
      for (let i = 1; i <= comic.content.length; i++) {
        pages.push(i);
      }
    }
    return pages;
  };

  const renderPages = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-[50dvh]">
          <PropagateLoader color={"#FFB23F"} />
        </div>
      );
    }
  
    if (comic && comic.content) {
      return comic.content.map((contentUrl, index) => {
        if (comic.type === 1) {
          // Image content
          return (
            <img
              key={index}
              ref={(el) => (pagesRef.current[index] = el)}
              src={contentUrl}
              alt={`story page ${index + 1}`}
              className="w-full"
            />
          );
        } else if (comic.type === 2) {
          // Video content
          return (
            <div
              key={index}
              ref={(el) => (pagesRef.current[index] = el)}
              className="flex justify-center items-center w-full h-[85vh] relative"
            >
              {loadingVideos[index] && (
                <div className="absolute">
                  <PropagateLoader color={"#FFB23F"} />
                </div>
              )}
              <video
                ref={(el) => (videoRefs.current[index] = el)}
                src={contentUrl}
                preload="auto"
                className="w-full h-full object-contain bg-black"
                controls
                muted
                autoPlay={index === currentPage - 1}
              />
            </div>
          );
        } else {
          return <div key={index}>Unknown content type</div>;
        }
      });
    } else {
      return <div>{t("comic missing")}</div>;
    }
  };
  

  const CustomDropdown = ({ options, value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
      onChange(option);
      setIsOpen(false);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div className="relative" ref={dropdownRef}>
        <div
          onClick={handleToggleDropdown}
          className="px-[1rem] bg-[#1010100F] text-[#C5C5C5] rounded-full text-lg font-semibold flex items-center"
        >
          <span className="mr-[1rem]">{value} &nbsp; </span>
          {isOpen ? (
            <ChevronUp fill="#C5C5C5" width="16" height="16" />
          ) : (
            <ChevronDown fill="#C5C5C5" width="16" height="16" />
          )}
        </div>
        {isOpen && (
          <div className="absolute mt-2 w-full bg-black bg-opacity-50 rounded shadow-lg z-10">
            {options.map((option) => (
              <div
                key={option}
                onClick={() => handleOptionClick(option)}
                className="px-2.5 lg:px-3 py-1 lg:py-2 bg-opacity-75 bg-gray-200 hover:bg-white hover:bg-opacity-50 text-[#3C3C3C] font-semibold"
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div id="comic-container" className="relative pb-[25rem] lg:pb-0 h-auto w-screen max-w-[1920px] justify-center p-2">

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="absolute inset-0 overflow-hidden">
        <img
          className="w-screen h-full object-cover"
          src="/assets/story/animara-story-bg.webp"
          alt="Background"
        />
      </div>
      <div className="lg:hidden absolute inset-0 z-[-1]">
        <img
          className="min-h-screen object-cover"
          src="/assets/story/animara-story-mobile-bg.webp"
          alt="Background"
          style={{
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        />
      </div>
      <div className="relative min-h-screen w-full flex flex-col px-[1rem] lg:px-[8rem] xl:px-[16rem] top-0 z-10 pt-4 lg:pt-10">
        <div className="flex flex-col justify-center items-start">
          <div
            onClick={handleBack}
            className={`text-[#80E8FF] font-semibold font-outfit flex justify-start items-center mb-[1rem]`}
          >
            <ChevronLeft fill={"#80E8FF"} width="15" height="15" />
            <span> &nbsp; {t("back")}</span>
          </div>
          <h1 className="text-[#FFC85A] text-4xl lg:text-5xl font-normal font-bigNoodle uppercase">
            {comic?.title[i18n.language.startsWith("en") ? "en" : "cn"]}
          </h1>
        </div>
        <div className="flex-row justify-end items-end text-[#C5C5C5] text-lg font-normal font-outfit -mt-9 lg:hidden hidden">
          {comic && comic.type === 1 && (
            <>
              <p>{t("page")}</p>
              <CustomDropdown
                options={renderPageOptions()}
                value={currentPage}
                onChange={handlePageChange}
              />
              <p className="hidden lg:block ml-[1rem]">
                {t("of")} &nbsp; &nbsp;
                {comic && i18n.language.startsWith("en") ? (
                  <>{comic?.content?.length || 0}</>
                ) : (
                  ""
                )}
              </p>
              <div className="hidden lg:block mx-[1rem]">
                <Divider stroke={"#C5C5C5"} fill={"#C5C5C5"} />
              </div>
              <div className="hidden lg:block" onClick={toggleFullScreen}>
                <ZoomIn fill={"#C5C5C5"} />
              </div>
            </>
          )}
        </div>
        <div className="h-[75vh] lg:h-[80vh] overflow-y-auto mt-[1.5rem] scroll-smooth">
          <div>{renderPages()}</div>
        </div>
      </div>
    </div>
  );
};

export default AnimaraStoryMain;
