import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import HighFidelityComicHero from "./HighFidelityComicHero";
import Navbar from "../../component/NavBar";
import Footer from "../../component/Footer";

const HighFidelityComicPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get('type');

  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);


  return (
    <div className="relative flex flex-col overflow-x-hidden overflow-auto items-center justify-center bg-black z-0">
      <Navbar isSticky/>
      <HighFidelityComicHero type={type} />
      {/* <Footer /> */}
    </div>
  );
};

export default HighFidelityComicPage;
