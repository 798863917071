import React from "react";
import { useTranslation } from "react-i18next";

const AniTokenomicHero = () => {
  const { t } = useTranslation();
  
  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="relative h-[90vh] w-screen max-w-[1920px] z-[30] flex flex-col items-center justify-center bg-[#000000]">

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="hidden lg:flex absolute inset-0 z-[-1]">
        <img
          className="w-screen object-cover"
          src="/assets/anitoken/anitokenomic-hero-bg.webp"
          alt="Background"
          preload="auto"
        />
      </div>
      <div className="flex lg:hidden absolute inset-0 z-[-1]">
        <img
          className="h-screen object-cover"
          src="/assets/anitoken/anitokenomic-hero-mobile-bg.webp"
          alt="Background"
          preload="auto"
        />
      </div>

      <img
        className="absolute left-[10%] lg:left-[20%] bottom-[15%] lg:bottom-[25%] transform -translate-x-1/2 -translate-y-1/2 z-[200] w-[60px] lg:w-[100px] floating-element"
        src="/assets/anitoken/anitokenomic-hero-coin.webp"
        alt=""
        preload="auto"
      />

      <img
        className="absolute left-[5%] lg:left-[12%] bottom-[5%] lg:bottom-[5%] transform -translate-x-1/2 -translate-y-1/2 z-[200] w-[38px] lg:w-[60px] floating-element"
        src="/assets/anitoken/anitokenomic-hero-coin-2.webp"
        alt=""
        preload="auto"
      />

      <img
        className="absolute right-[12%] bottom-[-10%] transform -translate-x-1/2 -translate-y-1/2 z-[200] w-[58px] lg:w-[100px] floating-element"
        src="/assets/anitoken/anitokenomic-hero-coin-3.webp"
        alt=""
        preload="auto"
      />

      <div className="flex flex-col h-[150vh] lg:h-[90vh]">
        {/* title content */}
        <div className="mt-44 flex flex-col justify-center items-center gap-[2rem] mb-[10rem]">
          {/* title */}
          <h1 className="w-[70%] text-center text-5xl xs:text-7xl lg:text-[7rem] lg:leading-[7rem] px-4 text-[#FFC85A] font-normal font-bigNoodle tracking-wider uppercase">
            {formatStringBreaks(t("anitokenomic hero title"))}
          </h1>

          {/* description */}
          <p className="mt-0 w-[80%] md:w-1/2 lg:w-[50%] text-[#FFFFFF] text-center text-xs xs:text-sm font-medium font-outfit transition-opacity duration-1000">
            {t("anitokenomic hero desc")}
          </p>
        </div>

        {/* supplimentary content */}
        <div className="flex flex-col mt-[-8rem] xs:mt-[-5rem] justify-center items-center gap-[1rem]">
          {/* anitokenomic */}
          <div className="flex flex-col justify-center items-center">
            <p className="text-[#FFFFFF] text-center text-base font-medium font-outfit transition-opacity duration-1000">
              {t("anitokenomic symbol")}
            </p>
            <h1 className="text-center text-2xl text-[#FFC85A] font-normal font-bigNoodle tracking-wider uppercase">
              {t("anitokenomic symbol desc")}
            </h1>
          </div>

          {/* divider */}
          <div className="h-[1px] bg-[#C5C5C5] w-[20%]"></div>

          {/* total supply */}
          <div className="flex flex-col justify-center items-center">
            <p className="text-[#FFFFFF] text-center text-base font-medium font-outfit transition-opacity duration-1000">
              {t("anitokenomic sub title 1")}
            </p>
            <h1 className="text-center text-2xl text-[#FFC85A] font-normal font-bigNoodle tracking-wider uppercase">
              {t("anitokenomic sub desc 1")}
            </h1>
          </div>

          {/* divider */}
          <div className="h-[1px] bg-[#C5C5C5] w-[20%]"></div>

          {/* blockchain */}
          <div className="flex flex-col justify-center items-center">
            <p className="text-[#FFFFFF] text-center text-base font-medium font-outfit transition-opacity duration-1000">
              {t("anitokenomic sub title 2")}
            </p>
            <h1 className="text-center text-2xl text-[#FFC85A] font-normal font-bigNoodle tracking-wider uppercase">
              {t("anitokenomic sub desc 2")}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AniTokenomicHero;
