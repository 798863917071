import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useVisibility from "../../component/UseVisibility";

const MainV2Referal = () => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const isVisible = useVisibility(sectionRef, 0.8);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div
      ref={sectionRef}
      className="relative w-screen lg:h-full max-w-[1920px] bg-[#000000] flex mx-auto py-[2rem]"
    >

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="absolute top-0 w-full z-[1000]">
        {!isMobile ? (
          <video
            src="https://storage.animara.world/warning-bar.webm"
            className="w-full h-full z-200 scale-[1]"
            autoPlay
            loop
            muted
            playsInline
          />
        ) : (
          <img
            src="/assets/mainV2/warning.webp"
            className="w-full h-full scale-[2] -mt-8"
            alt="Event Background"
          />
        )}
      </div>

      <div className="hidden lg:flex absolute top-0 left-0 pointer-events-none z-[30] mt-4">
        <img
          src="/assets/mainV2/mainV2-referal-bg-new.webp"
          className="w-full h-full"
          alt="background referal"
        />
      </div>

      <div className="flex lg:hidden absolute top-0 left-0 pointer-events-none z-[30]">
        <img
          src="/assets/mainV2/mainV2-referal-bg-mobile.webp"
          className="w-full h-full"
          alt="background referal"
        />
      </div>

      <div className="h-auto lg:h-full px-[1rem] lg:px-[4rem] mt-[16vh] lg:mt-16 xl:mt-[16vh] flex flex-col justify-center py-[4rem] z-[100] container">
        <div className="w-full py-[4rem] flex justify-center items-center mt-[8rem] lg:mt-80 z-96">
          <div className="w-full flex flex-col items-center justify-end">
            <h1
              className={`text-center text-[#FFC85A] text-[17.5vw] lg:text-[150px] font-normal font-bigNoodle transition-opacity duration-1000 -mt-12 lg:mt-4 mb-0 lg:-mb-4`}
            >
              {t("anireferal point 1 title")}
            </h1>
            <p
              className={`text-center text-white text-[3.15vw] lg:text-2xl font-outfit transition-opacity duration-1000 lg:pb-3`}
            >
              {t("anireferal point 2 title")}
            </p>
            <p
              className={`text-center text-white text-[3.15vw] lg:text-2xl font-outfit transition-opacity duration-1000`}
            >
              {t("anireferal point 3 title")}
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default MainV2Referal;
