import React, { useEffect, useState } from 'react';
import StoryHero from './StoryHero';
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';

const StoryPage = () => {
  const [notifyContent, setNotifyContent] = useState(false);
  const [closeLoading, setCloseLoading] = useState(false);
  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (isPageReady) {
        setCloseLoading(true);
        setTimeout(() => {
          setNotifyContent(true);
        }, 800);
      }
    }, 1200);
  }, [isPageReady]);

  return (
    <div className='bg-black'>
      <div className={`fixed bg-black w-full h-full flex items-center justify-center overflow-y-hidden ${notifyContent ? 'hidden' : 'block'} transition-all duration-700 ${closeLoading ? 'opacity-0' : 'opacity-100'}`}>
        <img
          className={`w-[268px] h-auto animate-pulse`}
          src="/assets/mainV2/hero-loading.png"
          alt="hero loading"
        />
      </div>

      <div className={`relative flex flex-col overflow-x-hidden items-center justify-center overflow-hidden bg-black z-0 transition-all duration-700 ${closeLoading ? 'opacity-100' : 'opacity-0'}`}>
        <Navbar className="absolute z-[1002]" isSticky/>
        <StoryHero setIsPageReady={setIsPageReady} />
        <Footer />
      </div>

    </div>
  );
};

export default StoryPage;
