import React from 'react';

const ZoomIn = ({ fill, width = "24", height = "24" }) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1677_1470)">
    <path d="M0.696541 6.04467C1.08131 6.04467 1.39308 5.73281 1.39308 5.34813V2.37827L5.87927 6.86352C6.01532 6.99949 6.19355 7.06747 6.37177 7.06747C6.55008 7.06747 6.7284 6.99949 6.86436 6.86343C7.13638 6.59141 7.13638 6.15036 6.86427 5.87843L2.3779 1.39308H5.34841C5.73309 1.39308 6.04495 1.08122 6.04495 0.69654C6.04495 0.311864 5.73318 0 5.34841 0H0.696541C0.311772 0 0 0.311864 0 0.69654V5.34822C0 5.73281 0.311772 6.04467 0.696541 6.04467Z" fill={fill}/>
    <path d="M19.3029 13.9548C18.9181 13.9548 18.6064 14.2666 18.6064 14.6513V17.6213L13.9821 12.997C13.71 12.725 13.269 12.725 12.997 12.997C12.725 13.269 12.725 13.7101 12.997 13.982L17.6212 18.6062H14.6514C14.2666 18.6062 13.9549 18.918 13.9549 19.3027C13.9549 19.6874 14.2666 19.9993 14.6514 19.9993H19.303C19.6878 19.9993 19.9995 19.6874 19.9995 19.3027V14.6513C19.9994 14.2666 19.6876 13.9548 19.3029 13.9548Z" fill={fill}/>
    <path d="M6.0169 12.997L1.39308 17.6212V14.6513C1.39308 14.2667 1.08131 13.9548 0.69654 13.9548C0.311771 13.9548 0 14.2667 0 14.6513V19.3029C0 19.6876 0.311771 19.9995 0.69654 19.9995H5.34794C5.73262 19.9995 6.04449 19.6876 6.04449 19.3029C6.04449 18.9182 5.73271 18.6064 5.34794 18.6064H2.37808L7.002 13.982C7.27402 13.71 7.27402 13.2689 7.0019 12.9969C6.73007 12.725 6.28902 12.725 6.0169 12.997Z" fill={fill}/>
    <path d="M19.3028 0H14.6512C14.2665 0 13.9547 0.311864 13.9547 0.69654C13.9547 1.08122 14.2665 1.39308 14.6512 1.39308H17.6212L13.1356 5.87889C12.8636 6.15092 12.8636 6.59197 13.1357 6.86399C13.2717 6.99995 13.45 7.06794 13.6282 7.06794C13.8065 7.06794 13.9848 6.99986 14.1208 6.8639L18.6064 2.37808V5.34813C18.6064 5.73281 18.9181 6.04467 19.3029 6.04467C19.6877 6.04467 19.9994 5.73281 19.9994 5.34813V0.69654C19.9994 0.311771 19.6875 0 19.3028 0Z" fill={fill}/>
    </g>
    <defs>
    <clipPath id="clip0_1677_1470">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
);

export default ZoomIn;