import React, { useState } from "react";

const ComicType = ({
  category,
  index,
  isChoosen,
  setActiveSlide,
  setShowComicContent,
  navigate,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const getImageSrc = () => {
    if (isChoosen) return `/assets/story/${category}_choosen.png`;
    if (isHovered) return `/assets/story/${category}_hover.png`;
    return `/assets/story/${category}_normal.png`;
  };

  const getButtonImageSrc = () => {
    return isHovered
      ? "/assets/mainV2/comic_redirect_hover.png"
      : "/assets/mainV2/comic_redirect.png";
  };

  const handleSlideClick = () => {
    // Trigger navigation or modal only if the category is not "memes"
    if (isChoosen) {
      if (category === "funs" || category === "characters") {
        setShowComicContent(true);
      } else if (category === "memeverse") {
        navigate("/story/high-fidelity-hero?type=1");
      }
    }
  };

  // Function to handle the button click
  const handleButtonClick = (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up to the parent div
    if (isChoosen) {
      handleSlideClick();
    }
  };

  return (
    <div
      onClick={() => {
        if (isChoosen && isHovered) {
          handleSlideClick();
        } else {
          setActiveSlide(index);
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="relative"
    >
      <img
        src={getImageSrc()}
        alt={`${category} option ${index}`}
        className="w-[35vh] lg:w-80 h-auto object-cover rounded-2xl transition-all duration-300"
        draggable="none"
      />

      {isChoosen && (
        <div
          className={`absolute bottom-12 -right-2 transform -translate-x-1/2 w-[4rem] transition-transform duration-300 ${
            isHovered ? "scale-125 opacity-90" : "scale-110 opacity-100"
          }`}
          onClick={handleButtonClick}
        >
          <a href="#" onClick={(e) => e.preventDefault()}>
            <img
              className="w-full"
              src={getButtonImageSrc()}
              alt="button cover"
            />
          </a>
        </div>
      )}
    </div>
  );
};

export default ComicType;